import { Col, Row } from "react-bootstrap";

interface IValueProps {
  title: string;
  value: number;
}

const PowerUsageValue = (props: IValueProps) => {
  return (
    <Row className="flex-column">
      <Col className={`font-size-14 font-weight-600 text-dark`}>
        {props.title}
      </Col>
      <Col className={`jakarta font-weight-600 font-size-36 text-dark`}>
        {props.value}W
      </Col>
    </Row>
  );
};

export default PowerUsageValue;
