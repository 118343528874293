import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useLazyRegenerateTemporaryUserVerificationCodeQuery,
  useTerminateTemporaryUserMutation,
  useUpdateTemporaryUserMutation,
} from "../../../../redux/api/temp-user/tempUserAPI";
import IRegisterTemporaryUserRequestDTO from "../../../../shared/oversight-core/dtos/request-dtos/register-remporary-user-request-dto";
import IRegenerateTemporaryUserVerificationCodeResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/regenerate-temporary-user-verification-code-response-dto";
import {
  EUserSharedStatus,
  userSharedStatusText,
} from "../../../../shared/oversight-core/enums/user-shared-status";
import { ITemporaryUserView } from "../../../../shared/oversight-core/interfaces/temporary-user-view";
import ConfirmationModal from "../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatDate } from "../../../../shared/oversight-core/utils/date-utils";
import {
  emailRegex,
  mobileNumberRegex,
  noSpecialCharsNotAllowOnlyNumbers,
} from "../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";

const defaultFormValues: IRegisterTemporaryUserRequestDTO = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  fromDate: "",
  toDate: "",
  spaceClusterId: "",
  spaceId: "",
};

interface IProps extends ModelContainerProps {
  show: boolean;
  sharedUser?: ITemporaryUserView;
  setUpdateCurrentStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareUserViewModal = (props: IProps) => {
  const { show, sharedUser, setUpdateCurrentStatus, ...rest } = props;

  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setHours(14, 0, 0, 0);
    return date;
  });
  const [toDate, setToDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(14, 0, 0, 0);
    return date;
  });
  const [regenerateCode, setRegenerateCode] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isOnlyPastDate, setIsOnlyPastDate] = useState(false);
  const [error, setError] = useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [updateTemporaryUser, { isLoading: isLoadingUpdateTemporaryUser }] =
    useUpdateTemporaryUserMutation();
  const [
    terminateTemporaryUser,
    { isLoading: isLoadingTerminateTemporaryUser },
  ] = useTerminateTemporaryUserMutation();
  const [
    triggerTemporaryUserVerificationCode,
    { isFetching: isFetchingTemporaryUserVerificationCode },
  ] = useLazyRegenerateTemporaryUserVerificationCodeQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IRegisterTemporaryUserRequestDTO>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (sharedUser && isEdit) {
      setValue("firstName", sharedUser.firstName);
      setValue("lastName", sharedUser.lastName);
      setValue("mobileNumber", sharedUser.contactNumber);
      setValue("email", sharedUser.email);
      setFromDate(new Date(sharedUser.space.fromDate));
      setToDate(new Date(sharedUser.space.toDate));
      setIsOnlyPastDate(new Date(sharedUser.space.fromDate) <= new Date());
    }
  }, [props.show, sharedUser, isEdit]);

  const regenerateCodeHandler = () => {
    if (sharedUser) {
      triggerTemporaryUserVerificationCode({
        contactNumber: sharedUser.contactNumber,
      })
        .unwrap()
        .then((res: IRegenerateTemporaryUserVerificationCodeResponseDTO) => {
          setRegenerateCode(res.verificationCode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onSubmit: SubmitHandler<IRegisterTemporaryUserRequestDTO> = (
    data: IRegisterTemporaryUserRequestDTO
  ) => {
    if (new Date(fromDate) < new Date() && !isOnlyPastDate) {
      setError("From Date/Time cannot be in the past.");
      return;
    }

    if (new Date(fromDate) >= new Date(toDate)) {
      setError("To Date/Time must be later than From Date/Time.");
      return;
    }

    if (new Date() > new Date(toDate)) {
      setError("To Date/Time cannot be in the past.");
      return;
    }

    if (sharedUser) {
      updateTemporaryUser({
        spaceClusterId: sharedUser.space.spaceClusterId,
        spaceId: sharedUser.space.id,
        userId: sharedUser.id,
        sharedSpaceHistoryId: sharedUser.sharedSpaceHistoryId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        contactNumber: data.mobileNumber,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      })
        .then(() => {
          showSuccessMessage("Temporary User Updated Successfully");
          setUpdateCurrentStatus((ps) => !ps);
          props.onClose && props.onClose();
          setIsEdit(false);
          setIsOnlyPastDate(false);
        })
        .catch(() => {
          showErrorMessage("Temporary User Updated Unsuccessfully");
        });
      return;
    }
  };

  const removeAccess = (
    id: string,
    spaceClusterId: string,
    spaceId: string
  ) => {
    terminateTemporaryUser({
      userIdentity: id,
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
    })
      .then(() => {
        showSuccessMessage("Temporary User Access Removed Successfully");
        setUpdateCurrentStatus((ps) => !ps);
        setShowDeleteConfirmModal(false);
        props.onClose && props.onClose();
      })
      .catch(() => {
        showErrorMessage("Temporary User Access Removed Unsuccessfully");
      });
  };

  return (
    <ModalContainer
      {...rest}
      title={isEdit ? `Edit Share Space Access` : `Share Space Access`}
      show={show}
      confirmButtonText={sharedUser && !isEdit ? `Terminate Access` : `Save`}
      cancelButtonText="Cancel"
      hideCancel={sharedUser && !isEdit}
      isButtonsVisible={
        sharedUser?.status === EUserSharedStatus.ACTIVE || isEdit
      }
      isShowEditIcon={sharedUser?.status === EUserSharedStatus.ACTIVE}
      confirmButtonVariant={sharedUser && !isEdit ? `red` : `blue`}
      onConfirm={() => {
        if (!isEdit && sharedUser) {
          setShowDeleteConfirmModal(true);
          return;
        }
        handleSubmit(onSubmit)();
      }}
      isLoading={
        isLoadingUpdateTemporaryUser || isLoadingTerminateTemporaryUser
      }
      onUpdate={() => {
        setIsEdit(true);
      }}
      onClose={() => {
        props.onCancel && props.onCancel();
        setIsEdit(false);
        setIsOnlyPastDate(false);
      }}
      onCancel={() => {
        if (isEdit) {
          setIsEdit(false);
        } else {
          props.onCancel && props.onCancel();
        }
        setIsOnlyPastDate(false);
      }}
    >
      <>
        <div className="card-container py-2 px-3">
          <Row className="align-items-center">
            <Col>
              <Row>
                <Col className="text-dark font-weight-500 font-size-14">
                  {sharedUser?.space.name}
                </Col>
              </Row>
              <Row>
                <Col className="text-light font-weight-400 font-size-12">
                  {sharedUser?.space.powerConsumers.length}&nbsp;
                  {sharedUser?.space.powerConsumers.length === 1
                    ? `Device`
                    : `Devices`}
                </Col>
              </Row>
            </Col>
            <Col
              className={`col-auto font-size-16 font-weight-400 ${
                sharedUser?.status === EUserSharedStatus.ACTIVE
                  ? `text-light-green`
                  : sharedUser?.status === EUserSharedStatus.TERMINATED
                  ? `text-danger`
                  : `text-light`
              }`}
            >
              {userSharedStatusText[sharedUser?.status as EUserSharedStatus]}
            </Col>
          </Row>
        </div>
        {sharedUser && !isEdit ? (
          <>
            <Row className="rol-cols-3 mt-4 gy-4 mx-2">
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    First Name
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    {sharedUser?.firstName}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    Last Name
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    {sharedUser?.lastName}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    Email
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14 text-break">
                    {sharedUser?.email}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    Mobile Number
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    {sharedUser?.contactNumber}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    From
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    {format(
                      new Date(sharedUser?.space.fromDate ?? new Date()),
                      "yyyy/MM/dd hh:mm a"
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-sm-6 col-md-4">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    To
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    {format(
                      new Date(sharedUser?.space.toDate ?? new Date()),
                      "yyyy/MM/dd hh:mm a"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {sharedUser?.status === EUserSharedStatus.ACTIVE && (
              <Row className="mt-5 mx-2">
                <Col>
                  <Row>
                    <Col className="text-dark font-weight-400 font-size-14">
                      Access Code
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    {isFetchingTemporaryUserVerificationCode ? (
                      <Col className="col-4 ps-0">
                        <SpinnerModal
                          show={isFetchingTemporaryUserVerificationCode}
                          withOverlay={false}
                          size="sm"
                        />
                      </Col>
                    ) : (
                      <>
                        <Col className="col-auto text-light font-weight-600 font-size-32 pe-0">
                          {regenerateCode
                            ? regenerateCode
                            : sharedUser.verificationCode}
                        </Col>
                        <Col className="col-auto">
                          <MaterialIcon
                            icon="copy_all"
                            color="#011F5D"
                            className="cursor-pointer"
                            onClick={() => {
                              const codeToCopy =
                                regenerateCode || sharedUser.verificationCode;
                              if (codeToCopy) {
                                navigator.clipboard.writeText(codeToCopy);
                              }
                            }}
                          />
                        </Col>
                      </>
                    )}
                    <Col className="col-auto ps-4">
                      <AppButton
                        iconName="cached"
                        text="Regenerate"
                        variant="transparentWithBorder"
                        size="extra-small"
                        iconColor="#011F5D"
                        onClick={() => regenerateCodeHandler()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <>
            <Row className="mt-4">
              <Col className="col-12 col-sm">
                <AppInput
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  register={register("firstName", {
                    required: "First name is required",
                    pattern: {
                      value: noSpecialCharsNotAllowOnlyNumbers,
                      message:
                        "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                    },
                  })}
                  errors={errors}
                />
              </Col>
              <Col className="mt-4 mt-sm-0">
                <AppInput
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  register={register("lastName", {
                    required: "Last name is required",
                    pattern: {
                      value: noSpecialCharsNotAllowOnlyNumbers,
                      message:
                        "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>
            <AppInput
              className="mt-4"
              placeholder="Mobile Number"
              label="Mobile Number"
              type="number"
              name="mobileNumber"
              register={register("mobileNumber", {
                required: "Mobile number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Please enter a valid Mobile number",
                },
              })}
              errors={errors}
            />
            <AppInput
              className="mt-4"
              label="Email"
              placeholder="Email"
              name="email"
              register={register("email", {
                required: "Email is required",
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              })}
              errors={errors}
            />
            <Row className="mt-4">
              <Col>
                <AppDatePicker
                  label="From"
                  inputDateFormat="yyyy/dd/MM hh:mm a"
                  selectedDate={fromDate}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  isInput
                  minDate={new Date()}
                  showTimeInput
                  disabled={isOnlyPastDate}
                />
              </Col>
              <Col>
                <AppDatePicker
                  label="To"
                  selectedDate={toDate}
                  inputDateFormat="yyyy/dd/MM hh:mm a"
                  onChange={(date) => {
                    setToDate(date);
                  }}
                  isInput
                  minDate={new Date()}
                  showTimeInput
                />
              </Col>
            </Row>
            {error && (
              <Row className="mt-1">
                <Col className="text-danger font-size-14 font-weight-400">
                  {error}
                </Col>
              </Row>
            )}
          </>
        )}
        <ConfirmationModal
          show={showDeleteConfirmModal}
          title="Remove Access"
          description="Are you sure want to remove access ?"
          onCancel={() => setShowDeleteConfirmModal(false)}
          onClose={() => setShowDeleteConfirmModal(false)}
          onConfirm={() => {
            if (sharedUser) {
              removeAccess(
                sharedUser.id,
                sharedUser.space.spaceClusterId,
                sharedUser.space.id
              );
            }
          }}
          isLoading={isLoadingTerminateTemporaryUser}
        />
      </>
    </ModalContainer>
  );
};

export default ShareUserViewModal;
