import { Col, Row } from "react-bootstrap";
import styles from "./breezeLink-device-information-card.module.scss";

interface IProps {
  deviceType: string;
  brand: string;
  model: string;
}

const BreezeLinkDeviceInformationCard = (props: IProps) => {
  const { deviceType, brand, model } = props;
  return (
    <Row
      className={`${styles.deviceCardDetails} justify-content-between px-2 py-3`}
    >
      <Col className="col-auto">
        <Row>
          <Col className="text-dark font-weight-500 font-size-16">
            Device Type
          </Col>
        </Row>
        <Row>
          <Col className="text-light font-weight-400 font-size-16">
            {deviceType}
          </Col>
        </Row>
      </Col>
      <Col className="col-auto">
        <Row>
          <Col className="text-dark font-weight-500 font-size-16">Brand</Col>
        </Row>
        <Row>
          <Col className="text-light font-weight-400 font-size-16">{brand}</Col>
        </Row>
      </Col>
      <Col className="col-auto">
        <Row>
          <Col className="text-dark font-weight-500 font-size-16">Model</Col>
        </Row>
        <Row>
          <Col className="text-light font-weight-400 font-size-16">{model}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BreezeLinkDeviceInformationCard;
