import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetSpaceClustersXYQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import { useLazyGetEnergyConsumptionQuery } from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetEnergyGenerationDataQuery } from "../../../redux/api/solar/solarAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  ISpaceClusterWithPowerGeneratorIds,
  SpaceClustersResponseDTO,
  ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewConsumedSpaceClusterByYearResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumed-space-cluster-by-year-response-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-generation-response-dto";
import { EConsumptionValueComparison } from "../../../shared/oversight-core/enums/consumption-comparison";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { EScheduleViewType } from "../../../shared/oversight-core/enums/schedule-view-type";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { EViewType } from "../../../shared/oversight-core/enums/view-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ConsumptionDataView } from "../../../shared/oversight-core/interfaces/entities/consumption-data-view";
import IExtendedEnergyConsumers from "../../../shared/oversight-core/interfaces/extended-energy-consumers";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import UsageCard from "../../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage-card";
import HighPowerDevices from "../../../shared/oversight-core/shared-pages/dashboard-widgets/high-power-devices/high-power-devices";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { capitalizeFirstLetter } from "../../../shared/oversight-core/utils/capitalize-first-letter";
import { devicePath } from "../../../shared/oversight-core/utils/device-path";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";
import UsageInfoModal from "./models/usage-info-modal/usage-info-modal";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
  manual: number[];
  semiAuto: number[];
}

const scheduleViewType = [
  {
    label: "Usage from Both Manual And Semi-Automated",
    value: EScheduleViewType.ALL,
  },
  {
    label: "Usage from Manual Schedule",
    value: EScheduleViewType.MANUAL,
  },
  {
    label: "Usage from Semi-Automated Schedule",
    value: EScheduleViewType.SEMI_AUTOMATED,
  },
];

const defaultUsage = {
  energyBill: 0,
  energyInUnits: 0,
};

const Home = () => {
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    useState<Option>(scheduleViewType[0]);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [semiAutomatedUsageThisMonth, setSemiAutomatedUsageThisMonth] =
    useState({
      ...defaultUsage,
    });
  const [semiAutomatedUsageLastMonth, setSemiAutomatedUsageLastMonth] =
    useState({
      ...defaultUsage,
    });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultUsage,
  });
  const [actualLastMonth, setActualLastMonth] = useState({
    ...defaultUsage,
  });
  const [consumers, setConsumers] = useState<IExtendedEnergyConsumers[]>([]);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
    manual: [],
    semiAuto: [],
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [chartDataAvailable, setChartDataAvailable] = useState(false);
  const [
    spaceClustersWithPowerGeneratorIds,
    setSpaceClustersWithPowerGeneratorIds,
  ] = useState<ISpaceClusterWithPowerGeneratorIds[]>([]);
  const [selectedSpace, setSelectedSpace] =
    useState<ISpaceClusterWithPowerGeneratorIds>();
  const [isShowAppTabsButton, setIsShowAppTabsButton] = useState(false);
  const [comparisonEnergyThisMonth, setComparisonEnergyThisMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [comparisonEnergyLastMonth, setComparisonEnergyLastMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [actualUsageDataFromThisMonth, setActualUsageDataFromThisMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [actualUsageDataFromLastMonth, setActualUsageDataFromLastMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [showUsageInfoModal, setShowUsageInfoModal] = useState(false);
  const [monthlyEnergySummaryView, setMonthlyEnergySummaryView] = useState<
    IMonthlyEnergySummaryView[]
  >([]);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpace }] =
    useLazyGetSpacesQuery();
  const [triggerGetSpaceClustersWithPowerGeneratorIds] =
    useLazyGetSpaceClustersXYQuery();
  const [triggerGetActualUsage, { isFetching: isFetchingActualUsage }] =
    useLazyViewSpaceClusterConsumptionByYearAndMonthQuery();
  const [
    triggerGetSemiAutomatedUsage,
    { isFetching: isFetchingSemiAutomatedUsage },
  ] = useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestPowerConsumers },
  ] = useLazyViewPowerConsumersWithHighConsumptionQuery();
  const [
    triggerViewEnergyGenerationData,
    { isFetching: isFetchingEnergyGeneration },
  ] = useLazyGetEnergyGenerationDataQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    {
      data: getEnergyConsumption,
      isSuccess: isSuccessEnergyConsumption,
      isFetching: isFetchingActualConsumptionByYear,
    },
  ] = useLazyGetEnergyConsumptionQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    triggerGetSpaceClustersWithPowerGeneratorIds()
      .unwrap()
      .then((res: SpaceClustersResponseDTO) => {
        setSpaceClustersWithPowerGeneratorIds(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClustersWithPowerGeneratorIds([]);
      });
  }, [triggerGetSpaceClustersWithPowerGeneratorIds]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      const selectedSpaceCluster = spaceClustersWithPowerGeneratorIds.find(
        (space) => space.id === billingSpaceFilter.spaceCluster.id
      );

      setSelectedSpace(
        selectedSpaceCluster ? { ...selectedSpaceCluster } : undefined
      );
    }
  }, [
    spaceClusters,
    billingSpaceFilter.spaceCluster.id,
    spaceClustersWithPowerGeneratorIds,
  ]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setActualThisMonth({
            energyBill: res.energy.energyBill,
            energyInUnits: res.energy.energyInUnits,
          });
          setActualLastMonth({
            energyBill: res.previousEnergy.energyBill,
            energyInUnits: res.previousEnergy.energyInUnits,
          });
          setComparisonEnergyThisMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualUsageDataFromThisMonth(res.usageDataFrom);
        })
        .catch(() => {
          setActualThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setActualLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setComparisonEnergyThisMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [triggerGetActualUsage, billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      const currentDate = new Date();

      const previousDate = new Date(currentDate);
      previousDate.setMonth(currentDate.getMonth() - 1);

      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: previousDate.getFullYear(),
        month: previousDate.getMonth() + 1,
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setComparisonEnergyLastMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualUsageDataFromLastMonth(res.usageDataFrom);
        })
        .catch(() => {
          setComparisonEnergyLastMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [triggerGetActualUsage, billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetSemiAutomatedUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
          setSemiAutomatedUsageThisMonth({
            energyBill: res.currentMonthEnergyView.energyBill,
            energyInUnits: res.currentMonthEnergyView.energyInUnits,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: res.previousMonthEnergyView.energyBill,
            energyInUnits: res.previousMonthEnergyView.energyInUnits,
          });
        })
        .catch(() => {
          setSemiAutomatedUsageThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id && selectedDate) {
      const viewEnergyAs: EEnergyViewType =
        selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
      const viewConsumptionAs: EConsumptionViewTypes =
        EConsumptionViewTypes.ALL;
      const viewScheduleAs: EScheduleViewType =
        selectedScheduleViewType.value as EScheduleViewType;

      triggerViewActualConsumptionByYearQuery({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: selectedDate.getFullYear(),
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewConsumedSpaceClusterByYearResponseDTO) => {
          const consumedEnergyBill = res.orderedMonthlyEnergySummary.map(
            (energy) => energy.energySummaryView.consumedEnergySummary
          );
          const manualScheduleEnergyBill = res.orderedMonthlyEnergySummary.map(
            (energy) => energy.energySummaryView.manualScheduleEnergySummary
          );
          const semiAutomatedScheduleEnergyBill =
            res.orderedMonthlyEnergySummary.map(
              (energy) => energy.energySummaryView.manualScheduleEnergySummary
            );
          setIsShowAppTabsButton(
            consumedEnergyBill.map((s) => s.energyBill === null) &&
              manualScheduleEnergyBill.map((s) => s.energyBill === null) &&
              semiAutomatedScheduleEnergyBill.map((s) => s.energyBill === null)
              ? false
              : true
          );
          setMonthlyEnergySummaryView(res.orderedMonthlyEnergySummary);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedDate,
    selectedButton,
    selectedScheduleViewType.value,
  ]);

  useEffect(() => {
    if (
      selectedButton === 1 &&
      selectedSpace &&
      selectedSpace.powerGeneratorIds?.length > 0
    ) {
      triggerViewEnergyGenerationData({
        powerGeneratorId:
          (selectedSpace && selectedSpace.powerGeneratorIds[0]) || "",
        year: selectedDate.getFullYear(),
      })
        .unwrap()
        .then((res: ViewEnergyGenerationResponseDTO) => {
          setChartData((ps) => {
            ps.solar =
              res.generatedEnergy?.map((ge) => +ge.toFixed(2)) ||
              Array.from({ length: 12 }, () => 0);
            ps.months = res.months || [];
            return { ...ps };
          });
        })
        .catch(() => {
          setChartData((ps) => {
            ps.solar = Array.from({ length: 12 }, () => 0);
            return { ...ps };
          });
        });
    } else {
      setChartData((ps) => {
        ps.solar = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [selectedButton, selectedSpace, selectedDate]);

  useEffect(() => {
    if (isSuccessEnergyConsumption && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.manualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.semiAutoConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyInUnits || 0
                : currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyBill || 0
            );

            return previousValue;
          },
          {
            months: [],
            actualConsumption: [],
            manualConsumption: [],
            semiAutoConsumption: [],
          }
        );

      setChartData((ps) => {
        ps.grid = data.actualConsumption.map((ac) => +ac.toFixed(2));
        ps.manual = data.manualConsumption.map((mc) => +mc.toFixed(2));
        ps.semiAuto = data.semiAutoConsumption.map((sac) => +sac.toFixed(2));
        ps.months = data.months;
        return { ...ps };
      });
    } else {
      setChartData((ps) => {
        ps.grid = Array.from({ length: 12 }, () => 0);
        ps.manual = Array.from({ length: 12 }, () => 0);
        ps.semiAuto = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [isSuccessEnergyConsumption, getEnergyConsumption]);

  const monthsList = chartData.months.map((m) => capitalizeFirstLetter(m));

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      setConsumers([]);
      triggerViewPowerConsumersWithHighConsumption({
        clusterId: billingSpaceFilter.spaceCluster.id,
        sortingOrder: ESortOrder.DESC,
        limit: 6,
        energyViewType: EEnergyViewType.ALL,
        viewType: EViewType.CONSUMED,
        year: moment().year(),
        month: moment().month() + 1,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewScheduledPowerConsumersSortedByEnergyResponseDTO) => {
          const extendedPowerConsumers = res.powerConsumers
            ? res.powerConsumers.map((powerConsumer) => {
                const path = devicePath(res?.devicePaths[powerConsumer.id]);
                return { ...powerConsumer, path };
              })
            : [];
          setConsumers(extendedPowerConsumers);
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0028
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    const energyArrays = [
      chartData.grid,
      chartData.manual,
      chartData.semiAuto,
      chartData.solar,
    ];

    const isDataAvailable = energyArrays.some((array) =>
      array.some((units) => units > 0)
    );

    setChartDataAvailable(isDataAvailable);
  }, [chartData]);

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <Row>
            <Col
              className="col-12 col-xl-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="h-50">
                <UsageCard
                  title="Usage This Month"
                  actualUsage={actualThisMonth}
                  scheduleUsage={semiAutomatedUsageThisMonth}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                  consumptionValueComparison={comparisonEnergyThisMonth}
                  usageDateFrom={actualUsageDataFromThisMonth}
                  predictedUsageText="Still learning..."
                />
              </div>
              <div className="h-50 mt-3 ">
                <UsageCard
                  title="Usage Last Month"
                  actualUsage={actualLastMonth}
                  scheduleUsage={semiAutomatedUsageLastMonth}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                  consumptionValueComparison={comparisonEnergyLastMonth}
                  usageDateFrom={actualUsageDataFromLastMonth}
                  predictedUsageText="Did not predict"
                />
              </div>
            </Col>
            <Col className="mt-3 mt-xl-0">
              <HighPowerDevices
                consumers={consumers || null}
                title="Devices with Highest Energy Consumption this Month"
                isFetching={isFetchingHighestPowerConsumers}
              />
            </Col>
          </Row>
          <div className="container-white mt-4">
            <Row className="align-items-center">
              <Col
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                className="col-12 col-lg-5 mt-3 mt-lg-0"
              >
                <AppSelect
                  options={scheduleViewType}
                  selectedValue={selectedScheduleViewType}
                  onChangeOption={(selectedUsageOption) => {
                    setSelectedScheduleViewType(selectedUsageOption);
                  }}
                  fontSize="12"
                  fontWeight="400"
                />
              </Col>
              <Col
                xs={{ order: 1 }}
                lg={{ order: 2 }}
                className="col-12 col-lg-7"
              >
                <Row className="align-items-center justify-content-md-end">
                  <Col className="col-12 col-sm-6 col-lg-5 col-xxl-2 mt-3 mt-sm-0">
                    <AppButton
                      text="More Info"
                      size="medium"
                      onClick={() => setShowUsageInfoModal(true)}
                      className="font-size-12 font-weight-500 px-2"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  {isShowAppTabsButton && (
                    <Col className="col-12 col-sm-6 col-lg-5 col-xxl-3 mt-3 mt-sm-0">
                      <AppTabs
                        buttons={[
                          {
                            buttonName: "Units",
                            selectedColor: "purpleColor",
                            id: 1,
                          },
                          {
                            buttonName: "Bill",
                            selectedColor: "purpleColor",
                            id: 2,
                          },
                        ]}
                        onChange={(id) => setSelectedButton(id)}
                        selectedTabId={selectedButton}
                      />
                    </Col>
                  )}
                  <Col className="col-12 col-sm-6 col-lg-6 col-xxl-4 mt-3 mt-sm-0">
                    <AppDatePicker
                      dateFormat="yyyy"
                      selectedDate={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      monthYearPicker={false}
                      yearPicker={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="position-relative mt-4">
              {selectedButton === 1 ? (
                selectedScheduleViewType.value === EScheduleViewType.ALL ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#CB343D"
                    borderColor3="#6729CC"
                    borderColor4="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#CC292920"
                    backgroundColor3="#8476DE20"
                    backgroundColor4="#29CC3917"
                    label1="Usage from Grid"
                    label2="Semi-Automated Schedule Usage"
                    label3="Manual Schedule Usage"
                    label4="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.semiAuto}
                    data3={chartData.manual}
                    data4={chartData.solar}
                    hasForthDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : selectedScheduleViewType.value ===
                  EScheduleViewType.MANUAL ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#6729CC"
                    borderColor3="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#8476DE20"
                    backgroundColor3="#29CC3917"
                    label1="Usage from Grid"
                    label2="Manual Schedule Usage"
                    label3="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.manual}
                    data3={chartData.solar}
                    hasThirdDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : selectedScheduleViewType.value ===
                  EScheduleViewType.SEMI_AUTOMATED ? (
                  <AreaChart
                    borderColor1="#18A1AA"
                    borderColor2="#CB343D"
                    borderColor3="#29CC39"
                    backgroundColor1="#DFFDFB4D"
                    backgroundColor2="#CC292920"
                    backgroundColor3="#29CC3917"
                    label1="Usage from Grid"
                    label2="Semi-Automated Schedule Usage"
                    label3="Solar Generation"
                    yAxesUnit="Units"
                    labels={monthsList}
                    data1={chartData.grid}
                    data2={chartData.semiAuto}
                    data3={chartData.solar}
                    hasThirdDataSet={true}
                    isChartDataAvailable={chartDataAvailable}
                  />
                ) : null
              ) : selectedScheduleViewType.value === EScheduleViewType.ALL ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#CB343D"
                  borderColor3="#6729CC"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#CC292920"
                  backgroundColor3="#8476DE20"
                  label1="Usage from Grid"
                  label2="Semi-Automated Schedule Usage"
                  label3="Manual Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.semiAuto}
                  data3={chartData.manual}
                  hasThirdDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : selectedScheduleViewType.value ===
                EScheduleViewType.MANUAL ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#6729CC"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#8476DE20"
                  label1="Usage from Grid"
                  label2="Manual Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.manual}
                  hasSecondDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : selectedScheduleViewType.value ===
                EScheduleViewType.SEMI_AUTOMATED ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#CB343D"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#CC292920"
                  label1="Usage from Grid"
                  label2="Semi-Automated Schedule Usage"
                  yAxesUnit="Rs."
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.semiAuto}
                  hasSecondDataSet={true}
                  isChartDataAvailable={chartDataAvailable}
                />
              ) : null}
              <SpinnerModal
                show={
                  isFetchingEnergyGeneration ||
                  isFetchingActualConsumptionByYear
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpace && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <UsageInfoModal
        show={showUsageInfoModal}
        onClose={() => setShowUsageInfoModal(false)}
        monthlyEnergySummeryView={monthlyEnergySummaryView}
      />
      <SpinnerModal show={isFetchingSpace} />
    </div>
  );
};

export default Home;
