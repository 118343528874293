import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyViewAllTemporaryUsersQuery } from "../../../redux/api/temp-user/tempUserAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import IViewAllTemporaryUsersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-temporary-users-response-dto";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { SearchField } from "../../../shared/oversight-core/enums/search-field";
import {
  EUserSharedStatus,
  userSharedStatusText,
} from "../../../shared/oversight-core/enums/user-shared-status";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISearchFilters } from "../../../shared/oversight-core/interfaces/search-filters";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { ITemporaryUserView } from "../../../shared/oversight-core/interfaces/temporary-user-view";
import AppSelect from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import Pagination from "../../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import ShareUserViewModal from "./share-user-view-modal/share-user-view-modal";

const headers = [
  {
    id: 1,
    columnName: "User",
    isSort: true,
    sortFunction: () => console.log("Sorting by User"),
    sort: false,
  },
  {
    id: 2,
    columnName: "Space",
  },
  {
    id: 3,
    columnName: "From",
    isSort: true,
    sortFunction: () => console.log("Sorting by From"),
    sort: false,
  },
  {
    id: 4,
    columnName: "To",
    isSort: true,
    sortFunction: () => console.log("Sorting by To"),
    sort: false,
  },
  {
    id: 5,
    columnName: "Mobile",
  },
  {
    id: 6,
    columnName: "Device",
  },
  {
    id: 7,
    columnName: "Status",
    isSort: true,
    sortFunction: () => console.log("Sorting by Status"),
    sort: false,
  },
  {
    id: 8,
    columnName: "",
  },
];

const initialFilters: ISearchFilters = {
  searchField: SearchField.FULL_NAME,
  searchText: "",
  pageNumber: 0,
  pageSize: 10,
  ascending: true,
};

const SharedUserList = () => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [filters, setFilters] = useState<ISearchFilters>({
    ...initialFilters,
  });
  const [searchText, setSearchText] = useState("");
  const [temporaryUsers, setTemporaryUsers] = useState<ITemporaryUserView[]>(
    []
  );
  const [totalElements, setTotalElements] = useState(0);
  const [sharedUser, setSharedUser] = useState<ITemporaryUserView>();
  const [showShareSpaceAccessModal, setShowShareSpaceAccessModal] =
    useState(false);
  const [updateCurrentStatus, setUpdateCurrentStatus] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);

  const [triggerAllTemporaryUsers, { isFetching }] =
    useLazyViewAllTemporaryUsersQuery();
  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceCluster }] =
    useLazyGetSpacesQuery();

  const searchOptions = [
    { value: SearchField.FULL_NAME, label: "By User Name" },
    { value: SearchField.SPACE_NAME, label: "By Space Name" },
  ];

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerAllTemporaryUsers({
        searchField: filters.searchField,
        searchValue: filters.searchText,
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
        sortBy: "fromDate",
      })
        .unwrap()
        .then((res: IViewAllTemporaryUsersResponseDTO) => {
          setTemporaryUsers(res.temporaryUser.elements);
          setTotalElements(res.temporaryUser.totalElements);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [
    triggerAllTemporaryUsers,
    filters,
    updateCurrentStatus,
    billingSpaceFilter.spaceCluster.id,
  ]);

  useEffect(() => {
    if (!searchText) {
      setFilters({ ...initialFilters });
    }
  }, [searchText]);

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  const getPlaceholder = () => {
    switch (filters.searchField) {
      case SearchField.SPACE_NAME:
        return "Search by Space";
      default:
        return "Search by Name";
    }
  };

  const onSearch = () => {
    if (searchText) {
      setFilters((ps) => ({
        ...ps,
        searchText: searchText,
        pageNumber: 0,
        pageSize: 10,
      }));
    }
  };

  return (
    <>
      {spaceClusters.length > 0 ? (
        <>
          <Row className="align-items-center justify-content-between">
            <Col className="col-12 col-xl-6">
              <Row className="align-items-center">
                <Col className="col-4 col-lg-3 col-xl-4 pe-0">
                  <AppSelect
                    selectedValue={searchOptions.filter(
                      (a) => a.value === filters.searchField
                    )}
                    options={searchOptions}
                    onChangeOption={(selectedOption) => {
                      setFilters((ps) => ({
                        ...ps,
                        searchField: selectedOption.value as SearchField,
                        pageNumber: 0,
                      }));
                    }}
                    borderRadiusStyle={"Right"}
                    className="font-size-12"
                  />
                </Col>
                <Col className="col-8 col-sm-6 col-lg-5 col-xl-6 ps-0">
                  <Search
                    placeholder={getPlaceholder()}
                    onSearch={onSearch}
                    handleSearchChange={(searchText) => {
                      setSearchText(searchText);
                    }}
                    value={searchText}
                    isWithOptions
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col className="col-auto">
        <ButtonWithBadge
          text="Filter"
          icon="filter_alt"
          onClick={() => console.log("Filter")}
          badgeValue={0}
        />
      </Col> */}
          </Row>
          {temporaryUsers.length > 0 ? (
            <div className="container-white position-relative mt-4">
              <DataTable
                headers={headers}
                data={temporaryUsers.map((u) => ({
                  data: [
                    <>
                      {u.firstName} {u.lastName}
                    </>,
                    <>{u.space.name}</>,
                    <>{format(new Date(u.space.fromDate), "dd/MM/yyyy")}</>,
                    <>{format(new Date(u.space.toDate), "dd/MM/yyyy")}</>,
                    <>{u.contactNumber}</>,
                    <>
                      <Row>
                        <Col className="col-4 pe-0">
                          <Row>
                            <Col className="col-1 pe-0">
                              {
                                u.space.powerConsumers.filter(
                                  (pc) =>
                                    pc.devicePowerState === EDeviceStatus.ON
                                ).length
                              }
                            </Col>
                            <Col>On</Col>
                            <Col className="ps-0 d-none d-xl-block">-</Col>
                          </Row>
                        </Col>
                        <Col className="col-4 ps-2">
                          <Row>
                            <Col className="col-1 pe-0">
                              {
                                u.space.powerConsumers.filter(
                                  (pc) =>
                                    pc.devicePowerState === EDeviceStatus.OFF
                                ).length
                              }
                            </Col>
                            <Col>Off</Col>
                          </Row>
                        </Col>
                      </Row>
                    </>,
                    <>
                      <div
                        className={
                          u.status === EUserSharedStatus.ACTIVE
                            ? `text-light-green`
                            : u.status === EUserSharedStatus.TERMINATED
                            ? `text-danger`
                            : `text-light`
                        }
                      >
                        {userSharedStatusText[u.status]}
                      </div>
                    </>,
                    <>
                      <MaterialIcon
                        size={18}
                        icon="visibility"
                        color="#011F5D"
                        className="cursor-pointer"
                        onClick={() => {
                          setShowShareSpaceAccessModal(true);
                          setSharedUser(u);
                        }}
                      />
                    </>,
                  ],
                }))}
              />
              <Row className="mt-3">
                <Col>
                  <Pagination
                    itemsPerPage={filters.pageSize}
                    length={totalElements}
                    currentPage={filters.pageNumber + 1}
                    updateCurrentPage={(pn) => {
                      setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
                    }}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div className="container-dash text-center text-light font-weight-400 font-size-14 mt-3">
              There are currently no shared spaces to display
            </div>
          )}
        </>
      ) : (
        <>
          {!isFetchingSpaceCluster && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <ShareUserViewModal
        show={showShareSpaceAccessModal}
        onCancel={() => setShowShareSpaceAccessModal(false)}
        onClose={() => setShowShareSpaceAccessModal(false)}
        sharedUser={sharedUser}
        setUpdateCurrentStatus={setUpdateCurrentStatus}
      />
      <SpinnerModal show={isFetching || isFetchingSpaceCluster} />
    </>
  );
};

export default SharedUserList;
