import moment from "moment";

export const formatDate = (selectDate: Date) => {
  return moment(selectDate).toISOString(true);
};

export const getMonthRangeToDisplay = (weekRange: Date[]) => {
  const startingMonthAndYear = {
    month: weekRange[0].getMonth(),
    year: weekRange[0].getFullYear(),
  };
  const endingMonthAndYear = {
    month: weekRange[6].getMonth(),
    year: weekRange[6].getFullYear(),
  };

  const momentStartOfTheWeek = moment(weekRange[0]);
  const momentEndOfTheWeek = moment(weekRange[6]);

  if (startingMonthAndYear.month === endingMonthAndYear.month) {
    return `${momentStartOfTheWeek.format("MMM - YYYY")}`;
  } else if (startingMonthAndYear.year !== endingMonthAndYear.year) {
    return `${momentStartOfTheWeek.format(
      "MMM - YYYY"
    )} - ${momentEndOfTheWeek.format("MMM - YYYY")}`;
  } else {
    return `${momentStartOfTheWeek.format("MMM")} - ${momentEndOfTheWeek.format(
      "MMM"
    )} ${momentStartOfTheWeek.format("YYYY")}`;
  }
};

export const getWeekRange = (date: Date, selectedDate: number): Date[] => {
  const dayOfWeek = date.getDay();

  let difference = dayOfWeek - 1;

  if (difference < 0) {
    difference = 6;
  }

  const dates: Date[] = [];
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(date.getDate() - difference);

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(startOfWeek);
    dates.push(new Date(newDate.setDate(newDate.getDate() + i)));
  }

  return dates;
};

export const getTodayStartAndEndDate = () => {
  const offset = new Date().getTimezoneOffset() * 60000; // Offset in milliseconds
  const localISOTime = new Date(Date.now() - offset).toISOString().slice(0, 10); // Local date in ISO format (YYYY-MM-DD)

  const startDate = `${localISOTime}T00:00:00.000+05:30`;
  const endDate = `${localISOTime}T23:59:59.999+05:30`;

  return { startDate, endDate };
};
