import { Col, Row } from "react-bootstrap";
import { EDeviceStatus } from "../../oversight-core/enums/device-status";
import { ISchedulingDateView } from "../../oversight-core/interfaces/scheduling-date.view";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import AppToggle from "../../oversight-core/ui-elements/app-toggle/app-toggle";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import { generateTimeRangeList } from "../../oversight-core/utils/time-utils";
import AcController from "../ac-controller/ac-controller";
import styles from "./ac-schedule.module.scss";

interface IProps {
  schedule: ISchedulingDateView;
  onRemove: () => void;
  onTimeChange: (time: Option) => void;
  onToggleChange: (powerState: EDeviceStatus) => void;
  onTemperatureChange: (temperature: number) => void;
  scheduleCounter: number;
  isConnectedAcController: boolean;
}

const AcSchedule = (props: IProps) => {
  const {
    schedule,
    onRemove,
    onTimeChange,
    onToggleChange,
    onTemperatureChange,
    scheduleCounter,
    isConnectedAcController,
  } = props;

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-02T00:00:00");
  const intervalMinutes = 5;

  return (
    <div className={`${styles.container} p-2`}>
      <Row className="align-items-center">
        <Col>
          <Row>
            <Col>
              <AppSelect
                options={generateTimeRangeList(
                  startTime,
                  endTime,
                  intervalMinutes
                )}
                menuHeight="18vh"
                isTimeSelection
                selectedValue={[
                  { value: schedule.atDate, label: schedule.atDate },
                ]}
                onChangeOption={(selectedOption) =>
                  onTimeChange(selectedOption)
                }
                rightSideIcon="schedule"
              />
            </Col>
            <Col
              className={`col-auto  ${
                !isConnectedAcController ? `pe-4` : `d-none d-md-block ps-0`
              }`}
            >
              <AppToggle
                isOn={schedule.powerState === EDeviceStatus.ON}
                onSwitch={() =>
                  onToggleChange(
                    schedule.powerState === EDeviceStatus.ON
                      ? EDeviceStatus.OFF
                      : EDeviceStatus.ON
                  )
                }
              />
            </Col>
            {isConnectedAcController &&
              schedule.powerState === EDeviceStatus.ON && (
                <Col className="ps-4 d-none d-md-block">
                  <AcController
                    value={schedule.temperature}
                    disabled={false}
                    onDecrease={() =>
                      onTemperatureChange(schedule.temperature - 1)
                    }
                    onIncrease={() =>
                      onTemperatureChange(schedule.temperature + 1)
                    }
                    isShowTemperatureText
                  />
                </Col>
              )}
          </Row>
        </Col>
        {scheduleCounter > 1 && (
          <Col className="col-auto">
            <AppButton
              text="Remove"
              iconOnly
              iconName="close"
              variant="red"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              size="other"
              iconSize={15}
            />
          </Col>
        )}
      </Row>
      {isConnectedAcController && (
        <Row className="mt-3 mt-md-0 justify-content-between mx-0">
          <Col className="col-auto d-block d-md-none ps-0">
            <AppToggle
              isOn={schedule.powerState === EDeviceStatus.ON}
              onSwitch={() =>
                onToggleChange(
                  schedule.powerState === EDeviceStatus.ON
                    ? EDeviceStatus.OFF
                    : EDeviceStatus.ON
                )
              }
            />
          </Col>
          <Col className="col-auto d-block d-md-none">
            <AcController
              value={schedule.temperature}
              disabled={false}
              onDecrease={() => onTemperatureChange(schedule.temperature - 1)}
              onIncrease={() => onTemperatureChange(schedule.temperature + 1)}
              isShowTemperatureText
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AcSchedule;
