import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";

interface IValueProps {
  title: string;
  value: number;
  subText?: string;
  trending?: "UP" | "DOWN" | "EQUAL";
}

const EnergyUsageValue = (props: IValueProps) => {
  return (
    <Row className="flex-column align-items-center">
      <Col>
        <Row>
          <Col className="font-size-14 font-weight-600 text-dark">
            {props.title}
          </Col>
        </Row>
      </Col>
      <Col className="jakarta font-weight-600 font-size-36 text-dark ps-2">
        {props.value}
        <span className="font-size-24">Units</span>
        {props.trending && props.trending === "UP" && (
          <MaterialIcon icon="trending_up" displayInline color="red" />
        )}
        {props.trending && props.trending === "DOWN" && (
          <MaterialIcon icon="trending_down" displayInline color="green" />
        )}
      </Col>
      {props.subText && (
        <Col className="text-light font-size-14 font-weight-500">
          {props.subText}
        </Col>
      )}
    </Row>
  );
};

export default EnergyUsageValue;
