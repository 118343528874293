import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetSpaceHierarchyQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyCheckIfPowerConsumerRegisteredToDRPQuery } from "../../../redux/api/usage-guide/usageGuideAPI";
import AddUpdateDeviceModal from "../../../screens/dashboard/space-clusters/components/models/add-update-device-modal/add-update-device-model";
import RemoveDeviceModal from "../../../screens/dashboard/space-clusters/components/models/remove-device-modal/remove-device-modal";
import ViewDeviceModal from "../../../screens/dashboard/space-clusters/components/models/view-device-modal/view-device-modal";
import CheckIfPowerConsumerRegisteredResponseDTO from "../../oversight-core/dtos/response-dtos/check-if-power-consumer-registered-response-dto";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../oversight-core/enums/device-status";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import { IPowerConsumerView } from "../../oversight-core/interfaces/entities/power-consumer";
import ISmartController from "../../oversight-core/interfaces/smart-controller";
import { ISpaceClusterShallowHierarchyView } from "../../oversight-core/interfaces/space-hierarchy";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppDropDown from "../../oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../oversight-core/ui-elements/app-toggle/app-toggle";
import LastKnownStatus from "../../oversight-core/ui-elements/last-known-status/last-known-status";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import AcController from "../ac-controller/ac-controller";
import styles from "./device-list-card-type-two.module.scss";

export interface IExtendedSmartDevice extends ISmartController {
  clusterId: string;
  spaceId: string;
}

interface IProps {
  deleteDevice: (
    clusterId: string,
    spaceId: string,
    deviceId: string,
    keepInProgramme: boolean,
    excludedSemiAutomatedSchedules: string[]
  ) => void;
  updateCurrentState: () => void;
  device: IPowerConsumerView;
  mainSpaceClusterId: string;
  mainSpaceId: string;
  smartDevices?: IExtendedSmartDevice[];
  isPowerConsumerUpdated?: boolean;
  setIsPowerConsumerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDeviceStatus?: (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus,
    isFromViewDeviceModal?: boolean
  ) => void;
  isUpdateDeviceStateLoading?: boolean;
  onAcTemperatureIncrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  powerConsumerIdOfOpenedViewDeviceModal?: string;
  onViewDeviceModalClose?: () => void;
}

const DeviceListCardTypeTwo = (props: IProps) => {
  const {
    deleteDevice,
    updateCurrentState,
    device,
    mainSpaceClusterId,
    mainSpaceId,
    smartDevices = [],
    updateDeviceStatus,
    onAcTemperatureDecrease,
    onAcTemperatureIncrease,
    powerConsumerIdOfOpenedViewDeviceModal,
    isPowerConsumerUpdated,
    onViewDeviceModalClose,
  } = props;

  const [showRemoveDevice, setShowRemoveDevice] = useState(false);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [showViewDeviceModal, setShowViewDeviceModal] = useState(false);
  const [powerConsumerPath, setPowerConsumerPath] = useState("");
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);
  const [smartPlugController, setSmartPlugController] =
    useState<IExtendedSmartDevice>();
  const [acController, setAcController] = useState<IExtendedSmartDevice>();
  const [isRegisteredDRP, setIsRegisteredDRP] = useState(false);
  const [isRemoveDeviceFromDRP, setIsRemoveDeviceFromDRP] = useState(false);

  const [triggerGetHierarchy] = useLazyGetSpaceHierarchyQuery();
  const [checkIfPowerConsumerRegisteredToDRP] =
    useLazyCheckIfPowerConsumerRegisteredToDRPQuery();

  useEffect(() => {
    if (smartDevices.length > 0) {
      setSmartPlugController(
        smartDevices.find(
          (sd) => sd.smartDeviceType === ESmartControllerType.SMART_PLUG
        )
      );
      setAcController(
        smartDevices.find(
          (sd) => sd.smartDeviceType === ESmartControllerType.AC_CONTROLLER
        )
      );
    } else {
      setSmartPlugController(undefined);
      setAcController(undefined);
    }
  }, [smartDevices]);

  const checkPowerConsumerRegisterToDRP = (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string
  ) => {
    checkIfPowerConsumerRegisteredToDRP({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
      powerConsumerId: powerConsumerId,
    })
      .unwrap()
      .then((res: CheckIfPowerConsumerRegisteredResponseDTO) => {
        setIsRegisteredDRP(res.registered);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onIncrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureIncrease &&
      acController &&
      acController.dynamicState.temperature < 30 &&
      acController.deviceConnectionState === EConnectionStatus.CONNECTED &&
      acController.dynamicState.powerState === EDeviceStatus.ON
    ) {
      onAcTemperatureIncrease(
        mainSpaceClusterId,
        mainSpaceId,
        acController.id,
        acController.dynamicState?.temperature + 1
      );
    }
  };

  const onDecrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureDecrease &&
      acController &&
      acController.dynamicState.temperature > 16 &&
      acController.deviceConnectionState === EConnectionStatus.CONNECTED &&
      acController.dynamicState.powerState === EDeviceStatus.ON
    ) {
      onAcTemperatureDecrease(
        mainSpaceClusterId,
        mainSpaceId,
        acController.id,
        acController.dynamicState?.temperature - 1
      );
    }
  };

  const appToggle = device.deviceConnectionState && (
    <AppToggle
      isOn={device.devicePowerState === EDeviceStatus.ON}
      onSwitch={() => {
        if (device.deviceConnectionState === EConnectionStatus.CONNECTED) {
          updateDeviceStatus &&
            updateDeviceStatus(
              device.clusterId,
              device.spaceId,
              device.id,
              device.devicePowerState === EDeviceStatus.ON
                ? EDeviceStatus.OFF
                : EDeviceStatus.ON
            );
        }
      }}
      size="sm"
      isDisable={
        device.deviceConnectionState === EConnectionStatus.CONNECTED
          ? false
          : true
      }
    />
  );

  useEffect(() => {
    if (mainSpaceClusterId && mainSpaceId) {
      triggerGetHierarchy({
        clusterId: mainSpaceClusterId,
        spaceId: mainSpaceId,
      })
        .unwrap()
        .then((res: ISpaceClusterShallowHierarchyView) => {
          let path = "";
          const ancestorSpaces = res.ancestorSpaces.slice();

          ancestorSpaces.forEach((ancestor, index) => {
            path +=
              index !== ancestorSpaces.length - 1
                ? `${ancestor.name} > `
                : `${ancestor.name}`;
          });

          setPowerConsumerPath(path);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [mainSpaceClusterId, mainSpaceId]);

  return (
    <>
      <div
        className={`${styles.deviceListCardContainer} cursor-pointer h-100 px-2 py-3`}
        onClick={() => setShowViewDeviceModal(true)}
      >
        <Row className="align-items-center justify-content-between mx-2">
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className={`${device.deviceType} p-3 rounded col-auto`}>
                <MaterialIcon icon={findIcon(device.deviceType)} />
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col className={`${styles.deviceName} col-auto`}>
                    {device.name}
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                    {device.powerUsageInWatt}W
                  </Col>
                  {device.deviceConnectionState && (
                    <Col className="ps-1">
                      <ActiveInactiveIndicator
                        isActive={
                          device.deviceConnectionState ===
                          EConnectionStatus.CONNECTED
                            ? true
                            : false
                        }
                      />
                    </Col>
                  )}
                </Row>
                <Row className="align-items-center">
                  <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                    {powerConsumerPath.length > 80
                      ? shortenedPath(powerConsumerPath)
                      : powerConsumerPath}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-auto pe-1">
            <Row className="align-items-center">
              {acController && (
                <Col className="px-4">
                  <AcController
                    value={acController.dynamicState?.temperature}
                    onIncrease={onIncrease}
                    onDecrease={onDecrease}
                    disabled={
                      acController.deviceConnectionState ===
                        EConnectionStatus.DISCONNECTED ||
                      (acController.deviceConnectionState ===
                        EConnectionStatus.CONNECTED &&
                        acController.dynamicState.powerState ===
                          EDeviceStatus.OFF)
                    }
                    disabledDecreaseButton={
                      acController.dynamicState?.temperature === 16
                    }
                    disabledIncreaseButton={
                      acController.dynamicState?.temperature === 30
                    }
                  />
                </Col>
              )}
              <Col
                className={`${
                  device.deviceConnectionState ===
                    EConnectionStatus.DISCONNECTED && `pe-0`
                } col-auto`}
              >
                <Row>
                  <Col
                    className={
                      device.deviceConnectionState ===
                      EConnectionStatus.DISCONNECTED
                        ? `opacity-50`
                        : `me-2`
                    }
                  >
                    {appToggle}
                  </Col>
                </Row>

                {device.deviceConnectionState ===
                  EConnectionStatus.DISCONNECTED && (
                  <Row>
                    <Col className="ms-2">
                      <LastKnownStatus />
                    </Col>
                  </Row>
                )}
              </Col>
              <Col className="col-auto">
                <AppDropDown
                  items={[
                    {
                      text: "Edit Device",
                      onClick: () => {
                        setShowAddUpdateModal(true);
                      },
                    },
                    {
                      text: "Remove Device",
                      onClick: () => {
                        checkPowerConsumerRegisterToDRP(
                          mainSpaceClusterId,
                          mainSpaceId,
                          device.id
                        );
                        setShowRemoveDevice(true);
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ViewDeviceModal
        device={props.device}
        show={
          (showViewDeviceModal ||
            (powerConsumerIdOfOpenedViewDeviceModal !== undefined &&
              powerConsumerIdOfOpenedViewDeviceModal === device.id)) &&
          !props.isUpdateDeviceStateLoading
        }
        setShowAddUpdateModal={setShowAddUpdateModal}
        setShowRemoveDevice={setShowRemoveDevice}
        onClose={() => {
          setShowViewDeviceModal(false);
          onViewDeviceModalClose && onViewDeviceModalClose();
        }}
        icon={findIcon(props.device.deviceType)}
        smartPlugController={smartPlugController}
        acController={acController}
        isPowerConsumerUpdated={isPowerConsumerUpdated}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
        spaceClusterId={mainSpaceClusterId}
        spaceId={mainSpaceId}
        updateDeviceStatus={updateDeviceStatus}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
      />
      <AddUpdateDeviceModal
        show={showAddUpdateModal}
        onClose={() => setShowAddUpdateModal(false)}
        onCancel={() => setShowAddUpdateModal(false)}
        spaceClusterId={mainSpaceClusterId}
        spaceId={mainSpaceId}
        updateCurrentState={updateCurrentState}
        device={device}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
      />
      <RemoveDeviceModal
        show={showRemoveDevice}
        deviceName={device.name}
        onClose={() => setShowRemoveDevice(false)}
        onCancel={() => setShowRemoveDevice(false)}
        onConfirm={() => {
          deleteDevice(
            mainSpaceClusterId,
            mainSpaceId,
            device.id,
            isRemoveDeviceFromDRP,
            scheduleIdList
          );
          setShowRemoveDevice(false);
          updateCurrentState();
        }}
        spaceClusterId={mainSpaceClusterId}
        linkedPowerConsumerId={device.id}
        setScheduleIdList={setScheduleIdList}
        isRegisteredDRP={isRegisteredDRP}
        isRemoveDeviceFromDRP={isRemoveDeviceFromDRP}
        setIsRemoveDeviceFromDRP={setIsRemoveDeviceFromDRP}
        smartPlugId={smartPlugController?.id || ""}
      />
    </>
  );
};

export default DeviceListCardTypeTwo;
