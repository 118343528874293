export enum SearchField {
  NAME = "name",
  USER_FULLNAME = "userName",
  ACCOUNT_NUMBER = "accountNumber",
  MOBILE_NUMBER = "mobileNumber",
  SERIAL = "serialKey",
  EMAIL = "email",
  ALL = "",
  FULL_NAME = "full_name",
  SPACE_NAME = "spaceName",
}
